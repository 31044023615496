<template lang="html">
  <div class="ze-back-button-component">
    <div class="ze-back-button"></div>
    {{text}}
  </div>
</template>

<script>
export default {
  props: ['text']
}
</script>