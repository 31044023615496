<template>
  <div>
    <div class='navigation'>
      <a :href='backUrl'><div class='back-arrow'></div>Back</a>
    </div>

    <div v-if='isFirstStep' class='first-step'>
      <div class='header'>
        <div class='medicine-monitoring-logo'></div>
        <div class='title-text'>Medicine Monitoring</div>
        <div class='secondary-text'>
          Medicine Monitoring will remind you when you need to have tests connected with the drugs you have been prescribed
        </div>
      </div>
      <div class='description'>
        In order to set this up we’d like to look at your prescribing history and we can suggest which medications need monitoring
      </div>
      <div class='form'>
        <div class='choice' :class="{ 'green-choice': !isButtonDisabled }">
          <div><div @click='toggleButton' class='radio'></div></div>
          <div class='label-text'>
            I am happy for the Zesty PHR to look at my prescriptions
          </div>
        </div>
      </div>
    </div>

    <div v-if='isSecondStep' class='second-step'>
      <div class='title-text'>Your active prescriptions</div>
      <div class='secondary-text'>
        We found active prescriptions for the following drugs that may require monitoring.
      </div>
      <div class='instruction-text'>
        Select the ones you’d like to have setup
      </div>
      <div class='form'>
        <div class='form-controls'>
          <div class='label-text'>
            <div class='warfarin-title'>Warfarin</div>
            <div class='warfarin-dosage'>3 mg</div>
          </div>
          <div @click='toggleButton' class='checkbox' :class='{ checked: !isButtonDisabled }'></div>
        </div>
      </div>
    </div>

    <a :href='nextUrl'>
      <ze-button text='Next' :disabled='isButtonDisabled'></ze-button>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    stepNumber: Number,
    nextUrl: String,
    backUrl: String
  },
  data() {
    return {
      isButtonDisabled: true
    };
  },
  computed: {
    isFirstStep () {
      return this.stepNumber == 1;
    },
    isSecondStep () {
      return this.stepNumber == 2;
    }
  },
  methods: {
    toggleButton() {
      this.isButtonDisabled = !this.isButtonDisabled;
    },
  }
};
</script>
