<template>
  <div
    :aria-label="`You are on step ${selected + 1} of ${number}`"
    class="ze-progress"
    role="status"
  >
    <div
      v-for="(item, index) in new Array(number)"
      aria-hidden="true"
      class="circle"
      :class="{ selected: index === selected }"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['number', 'selected'],
};
</script>
