<template>
  <div>
    <div class='header'>
      <div class='navigation'>
        <a :href='backUrl'><div class='back-arrow'></div>Back</a>
        <a
          :href="isSetupInProgress ? '#' : backUrl"
          :class='{ disabled: isSetupInProgress }'
        >Done</a>
      </div>
      <div class='title-text'>
        Setup your journal
      </div>
      <div class='block-label'>
        Active tiles
      </div>
    </div>
    <SlickList
      lockAxis="y"
      v-model="activeTiles"
      :useDragHandle="true"
      @input='handleDrop'
    >
      <SlickItem v-for="(tile, index) in activeTiles" :index="index" :key="index" class='active-tile'>
        <div class='remove-container' @click='openPopup(tile)'><div class='remove-icon'></div></div>
        <div class='tile-name'>{{ tile.display_name }}</div>
        <div v-handle class='drag-container'><div class='drag-icon'></div></div>
        <div class='green-small-icon' v-if=tile.show_green_icon></div>
        <div class='orange-small-icon' v-if=tile.show_orange_icon></div>
      </SlickItem>
    </SlickList>

    <div v-for="tile in inProgressTiles" class='active-tile'>
      <div class='remove-container' @click='removeTileInProgress(tile.name)'><div class='remove-icon'></div></div>
      <div class='tile-name'>{{ tile.display_name }}</div>
      <a :href='tile.setup_url' class='setup-tile-link'>Setup<div class='setup-arrow'></div></a>
    </div>

    <div v-if='prescribedTiles.length > 0'>
      <div class='block-label'>Add prescribed tiles</div>
      <div v-for="tile in prescribedTiles" class='add-new'>
        <div @click='addTile(tile)' class='add-icon'></div>
        <div class='add-label'>{{ tile.display_name }}</div>
        <div class='add-text'>{{ tile.description }}</div>
      </div>
    </div>

    <div v-if='availableTiles.length > 0'>
      <div class='block-label'>Add new tiles</div>
      <div v-for="tile in availableTiles" class='add-new'>
        <div @click='addTile(tile)' class='add-icon'></div>
        <div class='add-label'>{{ tile.display_name }}</div>
        <div class='add-text'>{{ tile.description }}</div>
      </div>
    </div>

    <ze-modal id='removeTilePopup'>
      <div class='remove-tile-mobile-popup'>
        <div class='popup-title'>Are you sure you would like to remove {{ popupTile.display_name }}</div>
        <div class='popup-body'>
          <div class='popup-desc'>Removing this tile from your PHR dashboard will mean your data will no longer be sent to the hospital and you will lose all your records.</div>
          <div class='choice' :class="{ 'green-choice': popupChoice=='no' }">
            <div><div @click="popupChoice='no'" class='radio'></div></div>
            <div class='label-text'>No, do not delete the {{ popupTile.display_name }} tile from my PHR</div>
          </div>
          <div class='choice' :class="{ 'red-choice': popupChoice=='yes' }">
            <div><div @click="popupChoice='yes'" class='radio'></div></div>
            <div class='label-text'>Yes, delete the {{ popupTile.display_name }} tile from my PHR</div>
          </div>
          <a v-if="popupChoice=='no'" class='ze-button bigger blue' href='#' @click="closePopup">Cancel</a>
          <a v-if="popupChoice=='yes'" class='ze-button bigger red' href='#' @click="removeTile">Delete</a>
          <ze-button v-if="popupChoice==null" klass='blue' disabled='true' text='Delete'></ze-button>
        </div>
      </div>
    </ze-modal>

  </div>
</template>

<script>
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
export default {
  components: {
    SlickItem,
    SlickList
  },
  directives: { handle: HandleDirective },
  props: {
    backUrl: String,
    updateUrl: String,
    initialActiveTiles: Array,
    initialPrescribedTiles: Array,
    allAvailableTiles: Array
  },
  data() {
    return {
      activeTiles: this.initialActiveTiles,
      inProgressTiles: [],
      popupTile: {},
      popupChoice: null
    };
  },
  computed: {
    isSetupInProgress () {
      return this.inProgressTiles.length > 0;
    },
    prescribedTiles () {
      let tileNames = [];
      this.activeTiles.forEach(tile => tileNames.push(tile.name));
      this.inProgressTiles.forEach(tile => tileNames.push(tile.name));

      return this.initialPrescribedTiles.filter(tile => !tileNames.includes(tile.name));
    },
    availableTiles () {
      let tileNames = [];
      this.activeTiles.forEach(tile => tileNames.push(tile.name));
      this.inProgressTiles.forEach(tile => tileNames.push(tile.name));
      this.initialPrescribedTiles.forEach(tile => tileNames.push(tile.name));

      return this.allAvailableTiles.filter(tile => !tileNames.includes(tile.name));
    },
  },
  methods: {
    handleDrop(dropResult) {
      this.sendActiveTiles();
    },
    removeTile() {
      this.activeTiles = this.activeTiles.filter(tile => tile.name != this.popupTile.name);
      this.sendActiveTiles({[this.popupTile.name]: {}});
      this.closePopup();
    },
    removeTileInProgress(tileName) {
      this.inProgressTiles = this.inProgressTiles.filter(tile => tile.name != tileName);
    },
    addTile(tile) {
      if (tile.setup_url) {
        this.inProgressTiles.push(tile);
      } else {
        this.activeTiles.push(tile);
        this.sendActiveTiles();
      }
    },
    openPopup(tile) {
      this.popupTile = tile;
      this.$root.visiblePopupId='removeTilePopup';
    },
    closePopup() {
      this.popupChoice = null;
      this.popupTile = {};
      this.$root.visiblePopupId='';
    },
    sendActiveTiles(widgetParams = {}) {
      let tileNames = [];
      this.activeTiles.forEach(tile => tileNames.push(tile.name));
      let bodyParams = Object.assign({ widget_list: tileNames }, widgetParams);
      let requestParams = {
        method: 'POST',
        body: JSON.stringify(bodyParams),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      }
      fetch(this.updateUrl, requestParams);
    }
  }
};
</script>
