<script>
export default {
  props: {
    initialCollection: Array
  },
  data () {
    return {
      collection: this.initialCollection,
      allRecordsLoaded: this.initialCollection.length == 0,
      isLoading: false,
      isBottomOfWindow: false
    }
  },
  computed: {
    isCollectionEmpty () { return this.collection.length == 0 },
    mayLoadRecords () { return this.isBottomOfWindow && !this.allRecordsLoaded && !this.isLoading }
  },
  methods: {
    handleScroll: function() {
      this.calculateBottomOfWindow();

      if (this.mayLoadRecords) {
        this.toggleLoading(true);

        fetch(this.loadMoreUrl, { 'credentials': 'same-origin' }).then((response) => {
          return response.json();
        }).then((records) => {
          if (records.length > 0) {
            this.collection = this.collection.concat(records);
          } else {
            this.allRecordsLoaded = true;
          }

          this.toggleLoading(false);
        });
      }
    },
    toggleLoading: function(isLoading) {
      this.isLoading = isLoading;
    },
    calculateBottomOfWindow: function() {
      let scrollPosition = window.scrollY || window.pageYOffset;
      this.isBottomOfWindow = (scrollPosition + screen.height) >= document.documentElement.offsetHeight;
    },
    fixBodyHeight: function() {
      $('body, html').css('height', 'auto');
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted(){
    this.fixBodyHeight();
    this.handleScroll();
  }
}
</script>
