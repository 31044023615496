<template lang="html">
  <div>
    <input :id="id"
           :type="type"
           :value="value"
           :name="name || 'text-input'"
           :class="{correct: isCorrect, error: isError}"
           :placeholder="placeholder"
           :alt="placeholder"
           :autocomplete="autocomplete"/>

    <div class="ze-desktop-input">
      <div class="error-message" v-html="errorMessage"></div>
    </div>
  </div>
</template>

<script>
var togglebool;
var current_index;

export default {
  props: [
    'id',
    'value',
    'placeholder',
    'type',
    'isCorrect', // shows check-mark
    'isError', // shows error mark and change colors to red
    'errorMessage',
    'name',
    'autocomplete'
  ]
}
</script>
