<template>
  <div @click='handleClick'>
    <HotelDatePicker
      :firstDayOfWeek='1'
      :singleDaySelection="true"
      :showYear="true"
      :startDate="new Date(new Date().getFullYear(), new Date().getMonth()-3, 1)"
      :endDate="new Date()"
      :i18n="customLabels"
      format="[Last test:] DD MMMM YYYY"
      :startingDateValue="initialDateStr ? new Date(initialDateStr) : initialDateStr"
      :endingDateValue="initialDateStr ? new Date(initialDateStr) : initialDateStr"
      @check-in-changed="handleDateChange"
    >
    </HotelDatePicker>
  </div>
</template>

<script>
import HotelDatePicker from 'vue-hotel-datepicker';
export default {
  components: {
    HotelDatePicker,
  },
  props: {
    initialDateStr: String
  },
  data(){
    return {
      customLabels: {
        'day-names': ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        'check-in': 'Add date of your last test',
        'check-out': '',
        'month-names': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      isCalendarVisible: false,
      dateStr: null
    }
  },
  methods: {
    handleDateChange: function(date) {
      this.updateDate(date);
      this.$emit('date-changed', this.dateStr);
    },
    updateDate: function(date) {
      if (date) {
        let dd   = ('0'+date.getDate()).slice(-2);
        let mm   = ('0'+(date.getMonth()+1)).slice(-2);
        let yyyy = date.getFullYear();
        this.dateStr = yyyy+'-'+mm+'-'+dd;
      } else {
        this.dateStr = date;
      }
    },
    handleClick: function() {
      this.calculateCalendarVisibility();

      if (this.isCalendarVisible) {
        this.updateLabel();
        this.handleScroll();
        this.addEventListener();
      };
    },
    calculateCalendarVisibility: function() {
      this.isCalendarVisible = document.getElementsByClassName('datepicker--open').length > 0;
    },
    addEventListener: function() {
      document.getElementById("swiperWrapper").addEventListener('scroll', this.handleScroll);
    },
    handleScroll: function() {
      this.loadNextMonth();
      this.hideDisabledMonth();
    },
    loadNextMonth: function() {
      let lastMonth = new Date('01 '+$('.datepicker__month-name:last').text());
      let lastMonthStr = lastMonth.getFullYear()+'-'+('0'+lastMonth.getMonth()).slice(-2);
      let currentMonth = new Date();
      let currentMonthStr = currentMonth.getFullYear()+'-'+('0'+currentMonth.getMonth()).slice(-2);
      if (currentMonthStr > lastMonthStr) {
        $('.next--mobile').click();
      }
    },
    hideDisabledMonth: function() {
      // If the entire month is disabled - hide it
      $('.datepicker__month-day--disabled').
        not('.datepicker__month-day--first-day-selected').
        filter(function(){ return $(this).text() === '1';}).
        closest('.datepicker__month').
        hide();
    },
    updateLabel: function() {
      $('.datepicker__dummy-input--is-active').text('Select a date');
      let $el = $('.datepicker__dummy-wrapper--is-active .datepicker__input:first-child').not('.datepicker__dummy-input--is-active, .datepicker__input--single-date');
      let text = $($el).text();
      if (text.search(/Last test:/) !== -1) {
        let newText = text.substr(14, 8);
        $($el).text(newText);
      };
    }
  }
};
</script>
