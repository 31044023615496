<template lang="html">
  <div :class="name"></div>
</template>

<script>
  export default {
    props: [
      'name'
    ]
  }
</script>