<template>
  <p class="error-wrapper">
    <span
      aria-label="Warning"
      class="warning"
      role="img"
    ></span>
    {{ text }}
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
