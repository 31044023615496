<template lang="html">
  <div
    id="popup-wrapper"
    aria-describedby="description"
    aria-labelledby="label"
    aria-modal="true"
    class="popup-wrapper"
    role="dialog"
    :style="mainStyles"
    tabindex="-1"
    @keydown.tab="focusHandler"
    @keyup="menuToggle"
  >
    <div class="popup">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],

  data() {
    return {
      focusableElements: [],
    };
  },

  computed: {
    mainStyles() {
      return {
        display: this.id !== this.$root.visiblePopupId ? 'none' : '',
      };
    },
  },

  created() {
    this.$parent.$on('show', this.paperlessPreferencesChallenge);
  },

  updated() {
    this.readPopUpOnly();
    this.focusOnPopUp();
    const popup = $('.popup');
    if (popup.length) {
      this.focusableElements = this.getFocusableElements(popup);
      if (this.focusableElements.length !== 0) {
        this.focusableElements[0].focus();
      }
    }
  },

  methods: {
    paperlessPreferencesChallenge() {
      if (this.id == 'paperPreferencesChangePopup') {
        const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        $.ajax({
          url: '/api/domain_event_logs',
          method: 'post',
          data: {
            event_type: 'paperless_preferences_challenge',
            triggered_by: 'patient',
          },
          headers: { 'X-CSRF-Token': csrf_token },
        });
      }
    },
    readPopUpOnly() {
      const hiddenElementsList = document.querySelectorAll('[aria-hidden="false"]');
      const shownElementsList = document.querySelectorAll('[aria-hidden="true"]');
      const areNonPopUpElementsShown = this.$root.visiblePopupId !== '';
      hiddenElementsList.forEach(function (element) {
        element.setAttribute('aria-hidden', areNonPopUpElementsShown);
      });
      shownElementsList.forEach(function (element) {
        element.setAttribute('aria-hidden', areNonPopUpElementsShown);
      });
    },
    focusOnPopUp() {
      if (document.getElementById('popup-wrapper')) {
        document.getElementById('popup-wrapper').focus();
      }
    },
    menuToggle(e) {
      if (e.keyCode === 27) {
        this.$root.visiblePopupId = 'close';
      }
    },
    getFocusableElements(popup) {
      return popup.find('button:visible, [href]:visible, input:visible, select:visible, textarea:visible');
    },
    focusHandler(event) {
      if (!this.focusableElements.length) return;

      if (this.focusableElements.length == 1) {
        event.preventDefault();
        return;
      }

      const activeElement = document.activeElement;
      const firstFocusableElement = this.focusableElements[0];
      const lastFocusedElement = this.focusableElements[this.focusableElements.length - 1];
      let element;

      if (activeElement === firstFocusableElement && event.shiftKey) {
        element = lastFocusedElement;
      } else if (activeElement === lastFocusedElement && !event.shiftKey) {
        element = firstFocusableElement;
      }

      if (element) {
        event.preventDefault();
        element.focus();
      }
    },
  },
};
</script>
