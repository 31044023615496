<template lang="html">
  <div class="ze-date-of-birth-step">
    <ze-date-of-birth
      autofocus="autofocus"
      :date="date"
      :error-message="errorMessage"
      :is-error="isError"
      @date-completed="updateSubmitButton"
    />
    <ze-button
      id="dob-form"
      :disabled="!isSubmitButtonEnabled"
      klass="bottom-fixed trackable"
      text="Next"
      type="submit"
    />
  </div>
</template>

<script>
export default {
  props: ['date', 'isError', 'errorMessage'],

  data() {
    return {
      isSubmitButtonEnabled: false,
    };
  },

  methods: {
    updateSubmitButton: function (isDateCompleted) {
      this.isSubmitButtonEnabled = isDateCompleted;
    },
  },
};
</script>
