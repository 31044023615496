<template lang="html">
  <div class="ze-input">
    <div class="labels" :class="{error: isError}" v-if="labelLeft || labelRight">
      <label class="left" :for="id" v-if="labelLeft">
        {{labelLeft}}
      </label>
      <label v-on:click="toggleHiddenChar" class="right" for="text-input" v-if="labelRight">
        {{labelRight}}
      </label>
    </div>
    <input :id="id"
           ref="input"
           :type="type"
           :value="value"
           v-if="!isParentValidation"
           :name="name || 'text-input'"
           :class="{correct: isCorrect, error: isError}"
           :placeholder="placeholder"
           :alt="placeholder"
           :autofocus="autofocus"
           :aria-label="placeholder"
           :autocomplete="autocomplete"/>

    <input v-if="type === 'password' && isParentValidation"
           :id="id"
           ref="input"
           type="password"
           v-model="validatedField"
           :name="name"
           :class="{correct: isCorrect, error: isError}"
           :placeholder="placeholder"
           :alt="placeholder"
           :autofocus="autofocus"
           :autocomplete="autocomplete"/>
    <div v-if="type === 'password' && isStrengthVisible"
         class="strength"
         :class="passwordStrengthItemsClass">
      <div>{{passwordStrengthLabel}}</div>
      <div v-for="(items, index) in new Array(4)"
           class="item"
           :class="{active: (index < strength)}"
      ></div>
    </div>
    <div class="error-message" v-html="errorMessage"></div>
    <label for="text-input" class="bottom" v-if="labelBottom">
      {{labelBottom}}
    </label>
  </div>
</template>

<script>
var current_index;

export default {
  props: [
    'id',
    'value',
    'placeholder',
    'labelLeft', // labels around the input
    'labelRight',
    'labelBottom', // is hidden if errorMessage is shown
    'type',
    'isCorrect', // shows check-mark
    'isError', // shows error mark and change colors to red
    'errorMessage',
    'isStrengthVisible', // only for type password
    'strength', // only for type 'password'
    'name',
    'isParentValidation', // for accessing validation errors from input model
    'autocomplete',
    'autofocus'
  ],
  data () {
    return {
      validatedField: '',
      togglebool: false
    }
  },
  computed: {
    passwordStrengthLabel () {
      switch (this.strength) {
        case 2:
          return 'Password: Fair'
        case 4:
          return 'Password: Strong'
        default:
          return 'Password strength'
      }
    },
    passwordStrengthItemsClass () {
      switch (this.strength) {
        case 2:
          return 'orange'
        case 4:
          return 'green'
      }
    }
  },
  mounted() {
    if(this.$props.autofocus) {
      setTimeout(this.focusInput, 100);
    }
  },
  methods: {
    focusInput: function() {
      this.$refs.input.focus();
    },
    toggleHiddenChar: function (event) {
      this.togglebool = !this.togglebool;
      if (this.togglebool) {
        this.$el.getElementsByTagName("input")[0].setAttribute('type', 'text');
        event.srcElement.textContent = "Hide";
      } else {
        this.$el.getElementsByTagName("input")[0].setAttribute('type', 'password');
        event.srcElement.textContent = "Show";
      }
    }
  }
}
</script>
