<template lang="html">
  <div class="ze-desktop-password">
    <input
      v-if="isPassword"
      v-model="value"
      type="password"
      aria-label="Enter your password"
      :id="id"
      :name="name"
      :class="{ error: isError }"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
    />
    <input
      v-if="!isPassword"
      v-model="value"
      type="text"
      aria-label="Enter your password"
      :id="id"
      :name="name"
      :class="{ error: isError }"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
    />
    <div
      class="label"
      @click="labelClick"
      :style="labelStyles"
    >
      {{ label }}
    </div>
    <div
      v-if="isStrengthVisible"
      class="strength"
      :class="passwordStrengthItemsClass"
    >
      <div>{{ passwordStrengthLabel }}</div>
      <div
        v-for="(items, index) in new Array(4)"
        class="item"
        :class="{ active: index < strength }"
      ></div>
    </div>
    <div class="ze-desktop-input">
      <div
        class="error-message"
        v-html="errorMessage"
      ></div>
    </div>
  </div>
</template>

<script>
var zxcvbn = require('zxcvbn');
export default {
  props: ['id', 'name', 'placeholder', 'isStrengthVisible', 'isError', 'errorMessage', 'autocomplete', 'autofocus'],
  data() {
    return {
      isPassword: true,
      value: '',
    };
  },
  methods: {
    labelClick() {
      this.isPassword = !this.isPassword;
    },
  },
  computed: {
    type() {
      return this.isPassword ? 'password' : 'text';
    },
    label() {
      return this.isPassword ? 'Show' : 'Hide';
    },
    labelStyles() {
      return this.value ? { visibility: 'visible' } : { visibility: 'hidden' };
    },
    strength() {
      return zxcvbn(this.value).score;
    },
    passwordStrengthLabel() {
      const labels = ['Weak', 'Fair', 'Moderate', 'Strong', 'Very Strong'];

      if (!this.value) {
        return 'Password strength';
      }

      return `Password: ${labels[this.strength]}`;
    },
    passwordStrengthItemsClass() {
      return this.strength < 3 ? 'orange' : 'green';
    },
  },
};
</script>
