<template lang="html">
  <div class="save-to-local-storage">
  </div>
</template>

<script>
  export default {
    mounted: function ()  {
      localStorage.setItem('previousPage', window.location.href);
    }
  }
</script>
