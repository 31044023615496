<template>
  <div class='third-step'>
    <div class='navigation'>
      <a :href='backUrl'><div class='back-arrow'></div>Back</a>
    </div>

    <div class='title-text'>Suggested monitoring</div>
    <div class='secondary-text'>
      NHS guidelines recommend the following tests for patients taking
    </div>
    <div class='prescription-container'>
      <div class='list-circle'></div>Warfarin
    </div>
    <div class='secondary-text'>
      Please select the tests you’d like to monitor:
    </div>

    <div class='form'>
      <div class='form-controls'>
        <div class='label-text'>
          <div class='warfarin-title'>Blood pressure</div>
          <div class='warfarin-dosage'>Every month</div>
        </div>
        <div @click='toggleBloodPressure' class='checkbox' :class='{ checked: isBloodPressureChecked }'></div>
      </div>
      <div v-if='isBloodPressureChecked' class='calendar-wrapper' :class="{ 'date-selected': bloodPressureDate }">
        <ze-journal-calendar @date-changed='updateBloodPressureDate' :initial-date-str='bloodPressureDate'>
        </ze-journal-calendar>
        <input type='hidden' name='medicine_monitoring[blood_pressure][enabled]' value=true>
        <input type='hidden' name='medicine_monitoring[blood_pressure][date]' :value='bloodPressureDate' v-if='bloodPressureDate'>
      </div>
    </div>
    <div class='form'>
      <div class='form-controls'>
        <div class='label-text'>
          <div class='warfarin-title'>Liver function test (LFT)</div>
          <div class='warfarin-dosage'>Every 3 months</div>
        </div>
        <div @click='toggleLiverFunction' class='checkbox' :class='{ checked: isLiverFunctionChecked }'></div>
      </div>
      <div v-if='isLiverFunctionChecked' class='calendar-wrapper' :class="{ 'date-selected': liverFunctionDate }">
        <ze-journal-calendar @date-changed='updateLiverFunctionDate' :initial-date-str='liverFunctionDate'>
        </ze-journal-calendar>
        <input type='hidden' name='medicine_monitoring[liver_function][enabled]' value=true>
        <input type='hidden' name='medicine_monitoring[liver_function][date]' :value='liverFunctionDate' v-if='liverFunctionDate'>
      </div>
    </div>
    <div class='form'>
      <div class='add-new'>
        <div class='add-icon'></div>
        <div class='add-text'>Add another test</div>
      </div>
    </div>

    <input type='hidden' name='widget_list[]' :value='widget' v-for="widget in widgetList">
    <input type='hidden' name='widget_list[]' value='medicine_monitoring' v-if="!widgetList.includes('medicine_monitoring')">
    <ze-button text='Next' type='submit' :disabled='isButtonDisabled'></ze-button>
  </div>
</template>

<script>
export default {
  props: {
    backUrl: String,
    widgetList: Array,
    bloodPressureTest: Object,
    liverFunctionTest: Object
  },
  data() {
    return {
      isBloodPressureChecked: Object.keys(this.bloodPressureTest).length != 0,
      isLiverFunctionChecked: Object.keys(this.liverFunctionTest).length != 0,
      bloodPressureDate: this.bloodPressureTest && this.bloodPressureTest.date,
      liverFunctionDate: this.liverFunctionTest && this.liverFunctionTest.date
    };
  },
  computed: {
    isButtonDisabled () {
      return !this.isBloodPressureChecked && !this.isLiverFunctionChecked;
    }
  },
  methods: {
    toggleBloodPressure() {
      this.isBloodPressureChecked = !this.isBloodPressureChecked;
    },
    toggleLiverFunction() {
      this.isLiverFunctionChecked = !this.isLiverFunctionChecked;
    },
    updateBloodPressureDate(date) {
      this.bloodPressureDate = date;
    },
    updateLiverFunctionDate(date) {
      this.liverFunctionDate = date;
    },
  }
};
</script>
