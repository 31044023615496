<template>
  <div class="wrapper">
    <div class="title-text no-side-mgn text-md-center">
      <h1>Report a technical issue</h1>
    </div>
    <div class="text-wrapper">
      <div class="support-desc">
        If you have a technical issue with the patient portal, please complete the form below.
      </div>
      <div class="support-desc icon-wrapper">
        <ZeInputErrorMessage text="Please avoid sharing medical information." />
      </div>
      <div
        v-if="primaryContact"
        class="support-desc"
      >
        If you want to contact Zesty Hospital about anything else, please get in touch via
        <span v-html="primaryContact" />
      </div>
    </div>
    <ZeSupportRequestForm
      :be-errors="beErrors"
      :erase-fields="eraseFields"
      :form-path="formPath"
      :is-patient-present="!!patient"
      :setup-fields="setupFields"
      :support-request-fields="supportRequestFields"
      @onFieldClear="(newErrors) => (beErrors = newErrors)"
      @onSubmit="onFormSubmit"
    />
  </div>
</template>

<script>
import ZeSupportRequestForm from '../components/ze-support-request-form.vue';
import ZeInputErrorMessage from '../components/ze-input-error-message';

import { requestParams } from '../helpers/induction_experience/fetch';

export default {
  components: {
    ZeInputErrorMessage,
    ZeSupportRequestForm,
  },

  props: {
    formPath: {
      type: String,
      required: true,
    },
    redirectPath: {
      type: String,
      required: true,
    },
    primaryContact: {
      type: String,
      default: '',
    },
    currentPatient: {
      type: String,
      default: '',
    },
    currentRequest: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      beErrors: [],
      supportRequestFields: {
        patientName: null,
        patientEmail: null,
        patientPhone: null,
        patientMRN: null,
        patientNHS: null,
        whatHasHappened: null,
      },
    };
  },

  computed: {
    patient() {
      return this.currentPatient ? JSON.parse(this.currentPatient) : null;
    },
    supportRequest() {
      return this.currentRequest ? JSON.parse(this.currentRequest) : {};
    },
  },

  mounted() {
    this.eraseFields();
  },

  methods: {
    async onFormSubmit() {
      const { patientNHS } = this.supportRequestFields;

      const params = {
        patient_full_name: this.supportRequestFields.patientName,
        patient_email: this.supportRequestFields.patientEmail,
        patient_mobile_phone: this.supportRequestFields.patientPhone,
        patient_mrn: this.supportRequestFields.patientMRN,
        patient_nhs: patientNHS ? patientNHS.replace(/-/g, '') : patientNHS,
        body: this.supportRequestFields.whatHasHappened,
        subject: this.supportRequest.subject,
        referrer: this.supportRequest.referrer,
      };

      const response = await fetch(this.formPath, requestParams({ support_request: params }, { method: 'POST' }));
      if (response.ok) {
        window.location.assign(`${this.redirectPath}?success=true`);
        return;
      }

      const { errors } = await response.json();
      this.beErrors = Object.keys(errors);
    },
    setupFields() {
      if (!this.patient) return;

      this.supportRequestFields = {
        patientName: `${this.patient.first_name} ${this.patient.last_name}`,
        patientEmail: this.patient.email,
        patientPhone: this.patient.mobile_number,
        patientMRN: this.patient.mrn_number,
        patientNHS: this.patient.nhs_number,
        whatHasHappened: this.supportRequestFields.whatHasHappened,
      };
    },
    eraseFields(fieldKey) {
      const tmpResult = Object.assign({}, this.supportRequestFields);
      delete tmpResult.whatHasHappened;

      if (fieldKey) {
        this.supportRequestFields[fieldKey] = null;
        return;
      }

      Object.keys(tmpResult).forEach((key) => {
        this.supportRequestFields[key] = null;
      });
    },
  },
};
</script>
