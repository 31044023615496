<template lang="html">
  <div
    v-on:click="notifyParent()"
    :id="dateId"
    class="day-wrapper"
    v-bind:class="{ 'day-selected': isSelected, 'day-wrapper-no-avail': !isAvailable }"
  >
    <div class="day-sum">{{dayName}}</div>
    <div class="dat-int">{{dayInt}}</div>
    <div>{{month}}</div>
  </div>
</template>

<script>
export default {
  props: {
    date: Array,
    dateId: String,
    isSelected: Boolean
  },
  computed: {
    dayName     () { return this.date[0] },
    dayInt      () { return this.date[1] },
    dateStr     () { return this.date[2] },
    month       () { return this.date[3] },
    isAvailable () { return this.date[4] }
  },
  methods: {
    notifyParent: function (event) {
      this.$emit('date-click', this.dateStr);
    },
  }
}
</script>
