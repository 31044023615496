export default function requestParams(data, options = {}) {
  const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' };

  const { method, headers: optionsHeaders, redirect, ...optionsRest } = options;

  const { body, headers } = (() => {
    if ([undefined, null].some((t) => data === t)) {
      return {
        body: data,
        headers: optionsHeaders || {},
      };
    }

    if (data.constructor === FormData) {
      return {
        body: data,
        headers: optionsHeaders || {},
      };
    }

    return {
      body: JSON.stringify(data),
      headers: {
        ...optionsHeaders,
        'Content-Type': 'application/json',
      },
    };
  })();

  return {
    body,
    headers: {
      ...headers,
      'X-CSRF-Token': token.content,
      'X-Requested-With': 'XMLHttpRequest',
    },
    method: method || 'PUT',
    redirect: redirect || 'manual',
    ...optionsRest,
  };
}
