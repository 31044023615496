<template lang="html">
  <input
    class="ze-button"
    :disabled="isActive"
    :type="type || 'button'"
    :value="userText"
  />
</template>

<script>
export default {
  props: ['text', 'type', 'disabled', 'count'],

  data() {
    return {
      countDown: this.count,
      isActive: this.disabled,
    };
  },

  computed: {
    userText() {
      return this.countDown > 0 ? `${this.text} (${this.countDown})` : this.text;
    },
  },

  created() {
    this.countDownTimer();
  },

  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;

          this.countDownTimer();
        }, 1000);
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>
