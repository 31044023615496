<template lang="html">
  <div class='calendar-functionality'>
    <div
      class='dates-wrapper'
      v-bind:class="{ 'touch-scrolling': applyTouchScrolling }"
    >
      <ze-slider-day
        v-for="(date, index) in dates"
        :key="index"
        v-bind:date="date"
        v-bind:is-selected="isSelected(date[2])"
        v-bind:date-id="dateId(index)"
        v-on:date-click="processClick"
      />
    </div>
    <div class='dates-array'>
      <div v-if="anySlots" class='day'>
        <div class='half-day'>
          <div class='date-titles'><h2>Morning</h2></div>
          <div v-for="(slot, index) in amSlots" class="date-button">
            <a :href="slot[1]" :aria-describedby="slotItemDateId">
              {{slot[0]}}
            </a>
          </div>
        </div>
        <div class='half-day'>
          <div class='date-titles'><h2>Afternoon</h2></div>
          <div v-for="slot in pmSlots" class="date-button">
            <a :href="slot[1]" :aria-describedby="slotItemDateId">
              {{slot[0]}}
            </a>
          </div>
        </div>
      </div>
      <div v-else class='no-callback-slot-message'>
        No <br> availability.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dates: Array,
    initialDate: String,
    baseSlotsUrl: String
  },
  data () {
    return {
      selectedDate: this.initialDate,
      amSlots: [],
      pmSlots: [],
      applyTouchScrolling: false
    }
  },
  computed: {
    anySlots () { return this.amSlots.length != 0 || this.pmSlots.length != 0 },
    slotsUrl () { return this.baseSlotsUrl + "&day=" + this.selectedDate },
    slotItemDateId () {
      let index = this.dates.findIndex( d => d[2] == this.selectedDate)
      return this.dateId(index);
    },
  },
  methods: {
    isSelected: function(date) {
      return this.selectedDate == date;
    },
    processClick: function(newDate) {
      this.selectedDate = newDate;
      this.updateSlots();
    },
    updateSlots: function() {
      fetch(this.slotsUrl).then((response) => { return response.json(); }).then((slots) => {
        this.amSlots = slots.filter(slot => slot[0].match(/am/));
        this.pmSlots = slots.filter(slot => !slot[0].match(/am/));
      });
    },
    setTouchScrolling: function() {
      this.applyTouchScrolling = true;
    },
    dateId: function (index) {
      return `day_${index}`
    }
  },
  mounted(){
    this.updateSlots();
    setTimeout(this.setTouchScrolling, 1000);
  }
}
</script>
