<template lang="html">
  <div
    class="ze-date"
    :class="{ error: isError }"
  >
    <label
      class="label"
      for="patient-date-of-birth"
      >Your date of birth</label
    >
    <br />
    <div class="label small">For example: 31/12/1970</div>
    <br />
    <input
      id="patient-date-of-birth"
      ref="dob"
      v-model="dob"
      aria-label="Enter your date of birth"
      autocomplete="off"
      inputmode="numeric"
      maxlength="10"
      name="patient[date_of_birth]"
      pattern="[0-9\/]+"
      placeholder="DD/MM/YYYY"
      @input="
        prettyPrintDob();
        dynamicDisable();
      "
      @keypress="limitLength"
    />
    <div
      v-if="isError"
      class="error-icon"
    />
    <p
      v-if="isError && errorMessage"
      class="error-message"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import $_date from '../mixins/$_date.js';

export default {
  mixins: [$_date],

  props: ['autofocus'],

  mounted() {
    if (this.$props.autofocus) {
      this.focusInput();
    }
  },

  methods: {
    focusInput() {
      this.$refs.dob.focus();
    },
  },
};
</script>
