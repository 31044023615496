const date = {
  data() {
    return {
      dob: '',
    };
  },
  props: ['date', 'isError', 'errorMessage'],
  methods: {
    prettyPrintDob: function () {
      this.dob = this.dob
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d\/]/g, '');
    },
    dynamicDisable: function () {
      const $button = document.querySelectorAll('input[type=submit]')[0];
      if (this.dob.length >= 10) {
        $button.disabled = false;
        $button.classList.remove('disabled');
      } else {
        $button.disabled = true;
        $button.classList.add('disabled');
      }
    },
    limitLength: function ($event) {
      if (this.dob.length >= 10) {
        $event.preventDefault();
      }
    },
  },
};
module.exports = date;
