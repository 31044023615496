<template lang="html">
  <div>
    <div class="form">
      <div class="form-body">
        <input
          name="patient[accept_terms_and_conditions]"
          type="hidden"
          :value="false"
        />
        <ze-checkbox
          id="accept-terms-and-conditions"
          class="pb-md-0"
          input_value="true"
          label="I have read and agreed to the terms and conditions"
          name="patient[accept_terms_and_conditions]"
        />
        <p
          v-if="isError"
          class="error-message mt-md-3 mb-md-0"
          v-text="errorMessage"
        />
      </div>
    </div>
    <div class="text-center">
      <ze-button
        klass="trackable"
        text="Next"
        type="submit"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isError: Boolean,
    errorMessage: String,
  },
};
</script>
