<template>
  <div>
    <div class='navigation'>
      <a :href='backUrl'><div class='back-arrow'></div>Back</a>
    </div>

    <div class='first-step'>
      <div class='header'>
        <div class='pain-pad-logo'></div>
        <div class='title-text'>Pain Pad</div>
        <div class='prescribed-label'>
          <div class='orange-plus-icon'></div>
          Prescribed Device
        </div>
        <div class='secondary-text'>
          Your doctor has asked you to report your pain scores every day. Pain Pad captures your pain from 0 - 10 and shares this data with the hospital.
        </div>
      </div>
      <div class='form'>
        <div class='choice' :class="{ 'green-choice': selectedChoice=='yes' }">
          <div><div  @click="selectedChoice='yes'" class='radio'></div></div>
          <div @click="selectedChoice='yes'" class='label-text'>
            Yes, I’d like to store my pain scores in my PHR
          </div>
        </div>
        <div class='choice' :class="{ 'red-choice': selectedChoice=='no' }">
          <div><div @click="selectedChoice='no'" class='radio'></div></div>
          <div @click="selectedChoice='no'" class='label-text'>
            No, I don’t want to store my pain scores in my PHR
          </div>
        </div>
      </div>
    </div>

    <a v-if="selectedChoice=='yes'" class='ze-button blue' :href='nextUrl'>Next</a>
    <a v-if="selectedChoice=='no'" class='ze-button red' href='#' @click="$root.visiblePopupId='painPadPopup'">Finish</a>
    <ze-button v-if="selectedChoice==null" klass='blue' disabled='true' text='Next'></ze-button>

  </div>
</template>

<script>
export default {
  props: {
    nextUrl: String,
    backUrl: String
  },
  data() {
    return {
      selectedChoice: null,
      doNotShowAgain: false
    };
  }
};
</script>
