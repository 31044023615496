<template lang="html">
  <div>
    <div class="clinical-system-label">{{ clinicalSystem }}</div>
    <h2 class="marg-0 text-break">{{ title }}</h2>
    <h3
      v-if="isWaitingListForm"
      class="mt-0"
    >
      Waiting list for {{ formattedWaitingListInfo }}
    </h3>
    <div
      v-if="date"
      class="questionnaire-details"
    >
      {{ date }}
    </div>
    <div :class="['new', 'questionnaire-status', statusClass]">{{ status ? status : 'New' }}</div>
    <button
      v-if="hintText"
      class="pull-right marg-desc question-desc"
      tabindex="0"
      @click="isHidden = !isHidden"
    >
      {{ hintTitle }}<i :class="!isHidden ? ['fa', 'fa-chevron-up'] : ['fa', 'fa-chevron-down']"></i>
    </button>
    <div class="clearfix"></div>

    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="!isHidden"
      class="f-description"
      v-html="hintText"
    ></p>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
export default {
  props: {
    clinicalSystem: {
      default: null,
      type: String,
    },
    contactUsLink: {
      default: null,
      type: String,
    },
    date: {
      default: null,
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
    status: {
      default: null,
      type: String,
    },
    statusClass: {
      default: null,
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    waitListProperties: {
      type: Object,
      default: () => {},
    },
  },

  data: function () {
    return {
      isHidden: true,
    };
  },

  computed: {
    isWaitingListForm() {
      return this.waitListProperties?.isWaitList;
    },
    formattedWaitingListInfo() {
      if (
        !(this.isWaitingListForm && this.waitListProperties?.procedureName && this.waitListProperties?.specialtyName)
      ) {
        return;
      }

      return `${this.waitListProperties.procedureName} (${this.waitListProperties.specialtyName})`;
    },
    hintTitle: function () {
      if (this.status === 'Expired') {
        return 'Why has this expired?';
      }
      return "What's this?";
    },
    hintText: function () {
      if (this.status === 'Expired') {
        return (
          'Since inviting you to complete this questionnaire we no longer require you to complete it. ' +
          'Please <a href=' +
          this.contactUsLink +
          '>contact us</a> if you want to find out more.'
        );
      }
      return this.description;
    },
  },
};
</script>
