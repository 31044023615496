<template lang="html">
    <button
      :type="type || 'button'"
      :disabled="disabled"
      @click="$emit('onClick', $event)"
      :class="getCssClasses"
    >
      <img
        src="/assets/check-small-white.png"
        v-if="shouldShowTick"
        alt="small-check"
      />
      <span v-text="text" />
    </button>
</template>

<script>
export default {
  props: {
    type: String,
    text: String,
    disabled: Boolean,
    isInProgress: Boolean,
    shouldShowTick: {
      default: true,
      type: Boolean
    },
    klass: {
      type: String
    }
  },

  computed: {
    getCssClasses() {
      const result = `${this.klass} ze-button`;
      if(!this.isInProgress) { return result }

      result.push(' progress');
      return result;
    }
  }
}
</script>
