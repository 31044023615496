<template lang="html">
  <div
    v-if="mobile"
    class="main letters-container"
  >
    <div
      v-if="isCollectionEmpty"
      class="missing-letters-message text-center"
      role="alert"
    >
      {{ missingMessage }}
    </div>
    <ul
      v-else
      role="list"
    >
      <li
        v-for="letter in collection"
        role="listitem"
      >
        <a
          class="letter"
          :href="letter.url"
        >
          <div
            class="title"
            :class="{ attachment: letter.has_attachment }"
          >
            <h2>{{ letter.title }}</h2>
          </div>
          <div class="date">Received: {{ letter.date.short }}</div>
          <div class="text">{{ letter.description }}</div>
          <div class="label">{{ letterStatus(letter) }}</div>
          <div class="right">
            <div
              v-if="letter.is_starred"
              class="star star-active"
            ></div>
            <div
              v-else
              class="star star-inactive"
            ></div>
            <div class="arrow"></div>
          </div>
          <p v-if="letter.is_new_version_available">
            <strong>This has new important revisions.<br />Please re-read.</strong>
          </p>
        </a>
      </li>
    </ul>
    <div
      v-if="noticeEnabled"
      class="letters-notice"
    >
      <div class="letters-notice__icon">
        <svg
          height="29.25"
          viewBox="0 0 29.25 29.25"
          width="29.25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs></defs>
          <g transform="translate(-3.375 -3.375)">
            <path
              class="a"
              d="M16.552,12.108a1.449,1.449,0,1,1,1.441,1.406A1.408,1.408,0,0,1,16.552,12.108Zm.1,2.51h2.686V24.75H16.65Z"
            />
            <path
              class="a"
              d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z"
            />
          </g>
        </svg>
      </div>
      <div class="letters-notice__message">
        <a :href="noticeLink">{{ noticeMessage }}</a>
      </div>
    </div>
  </div>
  <div
    v-else
    class="letters-list"
  >
    <div
      v-if="isCollectionEmpty"
      class="missing-letters-message text-center"
      role="alert"
    >
      {{ missingMessage }}
    </div>
    <div v-else>
      <ul
        class="analytic-letter-main"
        role="list"
      >
        <li
          v-for="letter in collection"
          role="listitem"
        >
          <a
            class="letter"
            :href="letter.url"
          >
            <div class="letter-main">
              <div class="date">Received: {{ letter.date.short }}</div>
              <div
                class="title"
                :class="{ attachment: letter.has_attachment }"
              >
                <h2>{{ letter.title }}</h2>
              </div>
              <div class="subject">{{ letter.description }}</div>
              <p v-if="letter.is_new_version_available">
                <strong>This has new important revisions.<br />Please re-read.</strong>
              </p>
            </div>
            <div class="letter-icons">
              <div class="label">{{ letterStatus(letter) }}</div>
              <ze-star-letter
                :active="letter.is_starred"
                :href="letter.url"
                method="put"
              ></ze-star-letter>
              <div class="chevron-right"></div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="noticeEnabled"
      class="letters-notice"
    >
      <div class="letters-notice__icon">
        <svg
          height="29.25"
          viewBox="0 0 29.25 29.25"
          width="29.25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs></defs>
          <g transform="translate(-3.375 -3.375)">
            <path
              class="a"
              d="M16.552,12.108a1.449,1.449,0,1,1,1.441,1.406A1.408,1.408,0,0,1,16.552,12.108Zm.1,2.51h2.686V24.75H16.65Z"
            />
            <path
              class="a"
              d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z"
            />
          </g>
        </svg>
      </div>
      <div class="letters-notice__message">
        <a :href="noticeLink">{{ noticeMessage }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ZeInfiniteScroll from './ze-infinite-scroll.vue';
export default {
  extends: ZeInfiniteScroll,

  props: {
    missingMessage: String,
    mobile: Boolean,
    noticeMessage: String,
    noticeLink: String,
    noticeEnabled: Boolean,
    attachment: String,
  },

  computed: {
    loadMoreUrl() {
      if (this.allRecordsLoaded) {
        return '';
      }

      const timestamp = this.collection[this.collection.length - 1].timestamp;
      const op = window.location.search.length > 0 ? '&' : '?';
      return window.location.href + op + 'format=json&before=' + timestamp;
    },
  },

  methods: {
    letterStatus(letter) {
      return letter.ui_status || this.unreadTitle(letter);
    },
    unreadTitle(letter) {
      return letter.is_unread ? 'Unread' : '';
    },
  },
};
</script>
