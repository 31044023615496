<template lang="html">
  <div class="ze-support-request-form">
    <form @submit="validateInputs($event)">
      <div class="form-support-request">
        <div class="what-happened-body">
          <div class="ze-input">
            <label for="support-request-body"> What’s happened? </label>
            <textarea
              id="support-request-body"
              name="body"
              class="text"
              :class="{ 'support-form-error': showErrorFor('body') }"
              v-validate="'required'"
              v-model="mutableSupportRequestFields.whatHasHappened"
              aria-describedby="hint-support-request-body"
            />
            <ze-input-error-message
              text="Please explain what happened."
              v-if="showErrorFor('body')"
            />
          </div>
          <span
            id="hint-support-request-body"
            class="hint-text text"
            v-if="!showErrorFor('body')"
          >
            Provide as much information as you can.
          </span>
        </div>
        <transition name="slide-fade">
          <div class="support-request-fields">
            <div
              class="support-request-wrapper"
              v-for="(inputData, key) in supportRequestInputs"
              :key="key"
            >
              <label
                class="support-request-label"
                :for="key"
              >
                {{ inputData.label
                }}<span
                  class="hint-text text"
                  v-if="isFieldOptional(key)"
                >
                  (optional)</span
                >
              </label>
              <div class="input-wrapper">
                <input
                  :id="key"
                  :aria-describedby="'hint-' + key"
                  class="support-request-input"
                  :class="{ 'support-form-error': showErrorFor(inputData.beName) }"
                  v-if="key === 'patientNHS'"
                  @keyup="validateNHS($event)"
                  :name="inputData.beName"
                  v-validate="inputData.validate"
                  :type="inputData.type"
                  v-model="mutableSupportRequestFields[key]"
                />
                <input
                  :id="key"
                  :aria-describedby="'hint-' + key"
                  class="support-request-input"
                  v-else
                  :class="{ 'support-form-error': showErrorFor(inputData.beName) }"
                  :type="inputData.type"
                  :name="inputData.beName"
                  @keyup="handleValidation"
                  v-validate="inputData.validate"
                  v-model="mutableSupportRequestFields[key]"
                />
                <div
                  class="close-icon"
                  v-if="mutableSupportRequestFields[key]"
                  @click="handleClearField(key)"
                />
              </div>
              <ze-input-error-message
                :text="inputData.error"
                v-if="showErrorFor(inputData.beName)"
              />
              <template v-else>
                <div
                  v-if="key === 'patientNHS'"
                  :id="'hint-' + key"
                  aria-label="Enter a 10-digit number."
                  class="hint-text text"
                  v-text="inputData.hint"
                />
                <div
                  v-else
                  :id="'hint-' + key"
                  class="hint-text text"
                  v-text="inputData.hint"
                />
              </template>
            </div>
          </div>
        </transition>
      </div>
      <div class="buttons-block text-center">
        <ze-submit-button
          text="Submit"
          type="submit"
          name="commit"
          :disabled="isSubmitDisabled"
          klass="submit"
          @onClick="validateInputs"
        />
        <ze-button
          klass="ze-button bigger white support-request-button"
          @onClick="goBack()"
          text="Cancel"
        />
      </div>
    </form>
  </div>
</template>
<script>
import ZeButton from './ze-button.vue';
import ZeCheckbox from './ze-checkbox.vue';
import ZeSubmitButton from './ze-submit-button.vue';
import NHSNumber from '../mixins/$_nhs-number.js';
import ZeInputErrorMessage from './ze-input-error-message';

export default {
  mixins: [NHSNumber],
  props: {
    formPath: String,
    beErrors: Array,
    onSubmit: Function,
    isPatientShown: Boolean,
    setupFields: Function,
    eraseFields: Function,
    supportRequestFields: {
      patientName: String,
      patientEmail: String,
      patientPhone: String,
      patientMRN: String,
      whatHasHappened: String,
      patientNHS: String,
    },
  },
  components: { ZeInputErrorMessage, ZeButton, ZeCheckbox, ZeSubmitButton },
  data() {
    return {
      xssAttackRegex: /^[^><]+$/,
      showErrors: true,
      isSubmitDisabled: false,
      mutableSupportRequestFields: this.supportRequestFields,
    };
  },
  methods: {
    async validateNHS(e) {
      const { patientNHS } = this.mutableSupportRequestFields;
      this.limitLength(patientNHS, e);
      this.mutableSupportRequestFields.patientNHS = this.prettyPrintNHS(patientNHS);
      this.isSubmitDisabled = this.isNHSLengthValid(this.mutableSupportRequestFields.patientNHS);
      await this.handleValidation();
    },

    handleClearField(key) {
      this.errors.remove(this.supportRequestInputs[key].beName);
      this.eraseFields(key);
      const newbeErrors = this.beErrors.filter((fieldName) => fieldName !== this.supportRequestInputs[key].beName);
      this.$emit('onFieldClear', newbeErrors);
      if (key !== 'patientNHS') return;
      this.isSubmitDisabled = false;
    },

    async handleValidation() {
      this.showErrors = !(await this.$validator.validateAll());
    },

    // Validation handler implementation compatible to IE 10+
    async validateInputs(e) {
      this.showErrors = true;
      const valid = await this.$validator.validateAll();
      if (valid) {
        this.showErrors = false;
        this.handleSubmit(e);
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.$emit('onSubmit', this.mutableSupportRequestFields);
    },

    goBack() {
      window.history.back();
    },

    showErrorFor(name) {
      return this.showErrors && (this.errors.has(name) || this.beErrors.includes(name));
    },

    isFieldOptional(key) {
      const field = this.supportRequestInputs[key];
      // Check if validate is an object with a 'required' property
      if (typeof field.validate === 'object') {
        return !field.validate.required;
      }
      // If validate is a string, check if it includes 'required'
      if (typeof field.validate === 'string') {
        return !field.validate.includes('required');
      }
      // Default to false if validate is not set
      return false;
    },
  },
  computed: {
    supportRequestInputs() {
      return {
        patientName: {
          label: 'Your name',
          hint: 'So we know what to call you.',
          type: 'text',
          validate: { required: true, regex: this.xssAttackRegex },
          error: 'Please use regular grammatical characters.',
          beName: 'patient_full_name',
        },
        patientEmail: {
          label: 'Your email address',
          hint: 'This can be any email address.',
          error: 'Please enter a valid address.',
          validate: { required: true, email: true },
          type: 'text',
          beName: 'patient_email',
        },
        patientPhone: {
          label: 'Your mobile number',
          hint: 'This can be any mobile number.',
          validate: { required: true, numeric: true },
          error: 'Please enter a valid number.',
          type: 'text',
          beName: 'patient_mobile_phone',
        },
        patientMRN: {
          label: 'Your MRN',
          hint: 'Also known as Medical Reference Number. Sometimes also referred to as Hospital Number.',
          type: 'text',
          validate: { required: false, regex: this.xssAttackRegex },
          error: 'Please use regular grammatical characters.',
          beName: 'patient_mrn',
        },
        patientNHS: {
          label: 'Your NHS Number',
          hint: 'Use the format ### - ### - ####.',
          type: 'tel',
          error: 'Please use the format ### - ### - ####.',
          validate: { required: false, regex: /[\d-]+$/ },
          beName: 'patient_nhs',
        },
      };
    },
  },

  watch: {
    supportRequestFields: function (newVal) {
      this.mutableSupportRequestFields = newVal;
      const { patientNHS } = newVal;
      this.mutableSupportRequestFields.patientNHS = this.prettyPrintNHS(patientNHS);
    },
  },
};
</script>
