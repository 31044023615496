<template lang="html">
  <div class="ze-nhs-number-step">
    <ze-nhs
      autofocus="autofocus"
      :error-message="errorMessage"
      :is-error="isError"
      :value="value"
      @nhs-completed="updateSubmitButton"
    />
    <ze-button
      :disabled="!isSubmitButtonEnabled"
      klass="bottom-fixed trackable"
      text="Next"
      type="submit"
    />
  </div>
</template>

<script>
export default {
  props: ['value', 'isError', 'errorMessage'],

  data() {
    return {
      isSubmitButtonEnabled: false,
    };
  },

  methods: {
    updateSubmitButton: function (isNhsCompleted) {
      this.isSubmitButtonEnabled = isNhsCompleted;
    },
  },
};
</script>
