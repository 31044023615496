export default {
  props: [
    'value',
    'isError', // shows error mark and change colors to red
    'errorMessage',
  ],

  methods: {
    prettyPrintNHS: function (nhs) {
      let NHS = nhs.match(/\d+/g);
      if (!NHS) return '';
      NHS = NHS.join('');
      const v_1 = NHS.substring(0, 3);
      const v_2 = NHS.substring(3, 6);
      const v_3 = NHS.substring(6, 10);
      let combined_array = [v_1, v_2, v_3];
      combined_array = combined_array.filter(function (el) {
        return el !== '';
      });
      return combined_array.join('-');
    },

    limitLength: function (nhs, $event) {
      if (nhs.length >= 12) {
        $event.preventDefault();
      }
    },

    isNHSLengthValid(nhs) {
      return nhs.length !== 0 && nhs.length < 12;
    },

    dynamicDisable() {
      const button = document.querySelectorAll('input[type=submit]')[0];

      if (this.nhsNumber.length >= 12) {
        button.disabled = false;
        button.classList.remove('disabled');
      } else {
        button.disabled = true;
        button.classList.add('disabled');
      }
    },
  },
};
