<template lang="html">
  <div class="ze-input">
    <input
      :id="id"
      v-model="mutatedValue"
      v-validate="'required|email'"
      autocapitalize="none"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :class="{'correct': fields['patient[email]'] && fields['patient[email]'].dirty && fields['patient[email]'].valid, error: (isError || isErrorRegistration === 'true') }"
      :name="name"
      :placeholder="placeholder"
      :type="type"
    >

    <label v-if="isError && !isSaml" :class="{error: isError}" for="patient[email]">Sorry, we couldn’t find an account with that email address. You can 
      <a href="/patients/email/new">reset your email address</a>.
    </label>

    <label v-if="isError && isSaml" :class="{error: isError}" for="patient[email]">
      Sorry, we couldn’t find an account with that email address or you don't have any appointments with this NHS trust. Please visit the patient portal for help.
    </label>

    <div v-if="isErrorRegistration === 'true'" class="ze-desktop-input">
      <div class="error-message">
        <div v-html="errorMessage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'placeholder',
    'labelLeft', // labels around the input
    'labelRight',
    'labelBottom', // is hidden if errorMessage is shown
    'type',
    'errorMessage',
    'isError',
    'isSaml',
    'isErrorRegistration',
    'value',
    'name',
    'autocomplete',
    'autofocus',
  ],

  data: function () {
    return {
      mutatedValue: this.value,
    }
  },
}
</script>

<style lang="scss">
  .desktop {
    .ze-input{
      input.error{
        background-image: none;
      }
      a {
        color: white;
      }
    }
    .ze-input .correct{
      background-position: 97%;
    }
    label.error {
      background: #d0011b;
      color: #ffffff;
      display: block;
      min-height: 44px;
      font-weight: bold;
      font-size: 15px;
      padding: 12px;
      border: 1px solid #d0011b;
    }
  }
</style>
