<template lang="html">
  <a class="nhs-back-btn" :href="href" @click="handleClick">
    <Icon class="me-2" name="chevronLeftSm" :size="15" aria-hidden="true" />Back
  </a>
</template>

<script>
export default {
  props: {
    buttonPath: { type: String, required: false },
    handler: { type: Function, required: false },
  },
  computed: {
    href() {
      return this.buttonPath || '#';
    },
  },
  methods: {
    handleClick(e) {
      if (typeof this.handler === 'function') {
        e.preventDefault();
        this.handler();
      }
    },
  },
};
</script>
