<template lang="html">
  <div
    :aria-label="`You are on step ${selected + 1} of ${number}`"
    class="ze-desktop-progress"
    role="status"
  >
    <div
      v-for="(item, index) in new Array(number)"
      aria-hidden="true"
      class="circle"
      :class="{
        selected: index === selected,
        'check-small-white-img': index < selected,
        number: index >= selected,
      }"
    >
      {{ index >= selected ? index + 1 : '&nbsp' }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['number', 'selected'],
};
</script>
