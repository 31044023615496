<template lang="html">
  <div class="ze-input">
    <div v-if="labelLeft || labelRight" class="labels" :class="{error: isError}">
      <label class="left" :for="id" v-if="labelLeft">
        {{labelLeft}}
      </label>
      <label v-on:click="toggleHiddenChar" class="right" for="text-input" v-if="labelRight">
        {{labelRight}}
      </label>
    </div>
    <input v-if="!isSaml" v-validate="{ rules: { regex: /^(?=.*[A-Za-z])(?=.*\d)[\D\d]{8,}$/, required: 'true' } }"
           :id="id"
           :class="{'correct': fields['patient[password]'] && fields['patient[password]'].dirty && fields['patient[password]'].valid }"
           name="patient[password]"
           type="password"
           placeholder="Enter your password"
           alt="Enter your password"
           :autocomplete="autocomplete"/>
    <input v-if="isSaml" v-validate="{ rules: { regex: /^(?=.*[A-Za-z])(?=.*\d)[\D\d]{8,}$/, required: 'true' } }"
           :class="{'correct': fields['patient[password]'] && fields['patient[password]'].dirty && fields['patient[password]'].valid }"
           name="saml_auth_patient[password]"
           type="password"
           placeholder="Enter your password"
           alt="Enter your password"/>
    <div class="error-message" v-html="errorMessage"></div>
    <label for="text-input" class="bottom" v-if="labelBottom">
      {{labelBottom}}
    </label>
  </div>
</template>

<script>
var togglebool;
var current_index;
export default {
  props: [
    'id',
    'labelLeft', // labels around the input
    'labelRight',
    'labelBottom', // is hidden if errorMessage is shown
    'isError', // shows error mark and change colors to red
    'errorMessage',
    'isSaml',
    'autocomplete'
  ],
  methods: {
    toggleHiddenChar: function (event) {
      togglebool = !togglebool;
      if (togglebool) {
        this.$el.getElementsByTagName("input")[0].setAttribute('type', 'text');
        event.srcElement.textContent = "Hide";
      } else {
        this.$el.getElementsByTagName("input")[0].setAttribute('type', 'password');
        event.srcElement.textContent = "Show";
      }
    }
  }
}
</script>
