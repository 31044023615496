<template lang="html">
  <span class='analytic-star'>
    <a :href="href"
       :aria-label="setAriaLabel"
       v-bind:class="cssClass"
       id="analytic-star"
       class="trackable"
       @click="handleClick($event)"></a>
  </span>
</template>

<script>
export default {
  props: [
    'active',
    'href',
    'method'
  ],
  computed: {
    setAriaLabel () {
      if (this.active) {
        return "Click here to unset your letter as a favourite"
      } else {
        return "Click here to set your letter as a favourite"
      }
    },
    cssClass () {
      if (this.active) {
        return "icon star star-active"
      } else {
        return "icon star star-inactive"
      }
    }
  },
  methods: {
    handleClick: function (event) {
      event.preventDefault()
      var a = $(event.target)
      $.ajax({
        url: a.attr('href'),
        method: this.method,
        success: function() {
          a.toggleClass('star-active');
          a.toggleClass('star-inactive');
        }
      })
    }
  }
}
</script>
