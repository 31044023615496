<template lang="html">
  <div class='ze-filtered-letters'>
    <div class='filters-block'>
      <div class='letter-dropdown-link' @click='toggleButton' aria-label='Click here to filter your letters'>
        <div v-if='isButtonDisabled' class='filter-dropdown'>
          <span> Filter </span>
          <div class='tick'/>
        </div>
        <div v-if='!isButtonDisabled' class='filter-dropdown'>
          <span> Close filter </span>
          <div class='close'/>
        </div>
      </div>
      <div class='main-text' v-if='!isButtonDisabled'>
        <span>Select a filter</span>
      </div>
      <div class='main-text' v-if='isButtonDisabled'>
        Showing:
        <span> {{filter_by | capitalize}} </span>
      </div>
      <br>
    </div>
    <div v-if='!isButtonDisabled' class='filter-by'>
      <div v-for="item in setUsersLinks" class='filter-item'>
        <div v-if="item.name == 'starred'" class='star'></div>
        <a :href="setLettersPath(item)" class='links-wrapper'>
          {{item.name | humanizeNames}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    missingMessage: String,
    filter_by: String,
    alpha_patient: Boolean,
    patient_letters_path: String,
    specialties: String
  },
  data() {
    return {
      isButtonDisabled: true,
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return 'Letters'
      if (value == 'All') return 'All'
      return value
    },
    humanizeNames(value) {
      var namesMap = {
        'unread': 'Unread',
        'all': 'All',
        'links-wrapper': 'Appointment',
        'clinic': 'Clinic',
        'starred': 'Starred',
        'default': value,
      }
      return namesMap.hasOwnProperty(value) ? namesMap[value] : namesMap['default']
    }
  },
  methods: {
    toggleButton() {
      this.isButtonDisabled = !this.isButtonDisabled;
    },
    setLettersPath(specialty) {
      var hrefMap = {
        'unread': this.patient_letters_path + '?specialty_id=unread',
        'all': this.patient_letters_path + '?scope=all',
        'links-wrapper': this.patient_letters_path,
        'clinic': this.patient_letters_path + '?specialty_id=clinic',
        'starred': this.patient_letters_path + '?specialty_id=starred',
        'default': this.patient_letters_path + '?specialty_id=' + specialty.id,
      }
      return hrefMap.hasOwnProperty(specialty.name) ? hrefMap[specialty.name] : hrefMap['default']
    }
  },
  computed: {
    setUsersLinks: function() {
      if (this.alpha_patient) {
        return JSON.parse(this.specialties).filter(function (f) {
          if (!f.id) return f
        })
      } else {
        return JSON.parse(this.specialties).filter(function (f) {
          if (!['links-wrapper', 'clinic'].includes(f.name)) return f
        })
      }
    }
  }
}
</script>
