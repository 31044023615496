<template lang="html">
  <div class="ze-five-digit-code">
    <div class="form-group">
      <label for="verification-code">Verification Code:</label>
      <input
        id="verification-code"
        :aria-label="isCodeValid ? 'Number that has been input meets entry criteria' : ''"
        autocomplete="off"
        autofocus
        class="form-control"
        :class="{ 'check-mark-green': isCodeValid }"
        name="two_factor_authentication_code[code]"
        placeholder="Enter your 5-digit code"
        type="number"
        @input="dynamicDisable"
        @keypress="limitLength"
      />
      <br />
      <template v-if="showRememberCode">
        <ze-checkbox
          id="remember-me-checkbox"
          label="Remember my code"
          name="two_factor_authentication_code[remember_me]"
        />
        <div class="secondary-text full-width">
          By checking this box you consent to Induction Zesty placing a cookie on your device. This will be for the
          purpose of remembering this device and to stop sending security codes. This cookie is valid for up to
          {{ two_factor_remember_token_expiry }} days.
          <br />
          <a
            class="contrast"
            href="/help_centre/articles/home/registration_login/what_is_the_remember_me_feature_and_how_does_it_work"
            >Learn more ></a
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  props: ['email_recovery', 'submit_id', 'two_factor_remember_token_expiry'],

  data() {
    return {
      isCodeValid: false,
    };
  },

  computed: {
    isCodeCompleted() {
      return document.getElementById('verification-code').value.length === 5;
    },
    showRememberCode() {
      return !this.email_recovery;
    },
  },

  methods: {
    limitLength($event) {
      if (document.getElementById('verification-code').value.length >= 5) {
        if ($event.key === 'Enter') {
          return;
        }

        $event.preventDefault();
      }
    },
    dynamicDisable() {
      const $button = document.getElementById(this.submit_id);
      if (document.getElementById('verification-code').value.length >= 5) {
        $button.disabled = false;
        $button.classList.remove('disabled');
        this.isCodeValid = true;
      } else {
        $button.disabled = true;
        $button.classList.add('disabled');
        this.isCodeValid = false;
      }
    },
  },
};
</script>
