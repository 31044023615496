import VeeValidate from 'vee-validate';
// FB component used in main app scope
import VersionPreview from '../components/version-preview.vue';
import Vue from 'vue';
import VueFilters from 'vue2-filters';
import ZeBackButton from '../components/ze-back-button.vue';
import ZeButton from '../components/ze-button.vue';
import ZeCheckbox from '../components/ze-checkbox.vue';
import ZeDateOfBirth from '../components/ze-date-of-birth.vue';
import ZeDateOfBirthStep from '../components/ze-date-of-birth-step.vue';
import ZeDesktopCancellationReasons from '../components/ze-desktop-cancellation-reasons.vue';
import ZeDesktopDateOfBirth from '../components/ze-desktop-date-of-birth.vue';
import ZeDesktopEmail from '../components/ze-desktop-email.vue';
import ZeDesktopFiveDigitCode from '../components/ze-desktop-five-digit-code.vue';
import ZeDesktopInput from '../components/ze-desktop-input.vue';
import ZeDesktopNhs from '../components/ze-desktop-nhs.vue';
import ZeDesktopPassword from '../components/ze-desktop-password.vue';
import ZeDesktopProgress from '../components/ze-desktop-progress.vue';
import ZeEmail from '../components/ze-email.vue';
import ZeFilteredLetters from '../components/ze-filtered-letters.vue';
import ZeFiveDigitCode from '../components/ze-five-digit-code.vue';
import ZeImage from '../components/ze-image.vue';
import ZeInfiniteScroll from '../components/ze-infinite-scroll.vue';
import ZeInput from '../components/ze-input.vue';
import ZeJournalCalendar from '../components/ze-journal-calendar.vue';
import ZeJournalMedicineMonitoringPreSetup from '../components/ze-journal-medicine-monitoring-pre-setup.vue';
import ZeJournalMedicineMonitoringSetup from '../components/ze-journal-medicine-monitoring-setup.vue';
import ZeJournalPainPadPreSetup from '../components/ze-journal-pain-pad-pre-setup.vue';
import ZeJournalSettings from '../components/ze-journal-settings.vue';
import ZeModal from '../components/ze-modal.vue';
import ZeNhs from '../components/ze-nhs.vue';
import ZeNhsBackIconText from '../components/ze-nhs-back-icon-text.vue';
import ZeNhsNumberStep from '../components/ze-nhs-number-step.vue';
import ZeNotFoundStep from '../components/ze-not-found-step.vue';
import ZePassword from '../components/ze-password.vue';
import ZePasswordIndicator from '../components/ze-password-indicator.vue';
import ZePatientLetters from '../components/ze-patient-letters.vue';
import ZeProgress from '../components/ze-progress.vue';
import ZeReportTechnicalIssueScreen from '../screens/ze-report-technical-issue-screen.vue';
import ZeSaveToLocalStorage from '../components/ze-save-to-local-storage.vue';
import ZeSlider from '../components/ze-slider.vue';
import ZeSliderDay from '../components/ze-slider-day.vue';
import ZeStarLetter from '../components/ze-star-letter.vue';
import ZeSubmitButton from '../components/ze-submit-button.vue';
import ZeSupportRequestForm from '../components/ze-support-request-form.vue';
import ZeTermsAndCondition from '../components/ze-terms-and-condition.vue';
import ZeTimeButton from '../components/ze-time-button.vue';
import ZlMenu from '../components/zl-menu.vue';

Vue.use(VeeValidate);
Vue.use(VueFilters);

Vue.component('FbVersionPreview', VersionPreview);
Vue.component('ZeInfiniteScroll', ZeInfiniteScroll);
Vue.component('ZeReportTechnicalIssueScreen', ZeReportTechnicalIssueScreen);
Vue.component('ZeInput', ZeInput);
Vue.component('ZeCheckbox', ZeCheckbox);
Vue.component('ZeJournalCalendar', ZeJournalCalendar);
Vue.component('ZeNhsBackIconText', ZeNhsBackIconText);
Vue.component('ZeJournalMedicineMonitoringPreSetup', ZeJournalMedicineMonitoringPreSetup);
Vue.component('ZeJournalMedicineMonitoringSetup', ZeJournalMedicineMonitoringSetup);
Vue.component('ZeJournalPainPadPreSetup', ZeJournalPainPadPreSetup);
Vue.component('ZeJournalSettings', ZeJournalSettings);
Vue.component('ZeButton', ZeButton);
Vue.component('ZeSubmitButton', ZeSubmitButton);
Vue.component('ZeTimeButton', ZeTimeButton);
Vue.component('ZeProgress', ZeProgress);
Vue.component('ZeFiveDigitCode', ZeFiveDigitCode);
Vue.component('ZeDateOfBirthStep', ZeDateOfBirthStep);
Vue.component('ZeDateOfBirth', ZeDateOfBirth);
Vue.component('ZeBackButton', ZeBackButton);
Vue.component('ZlMenu', ZlMenu);
Vue.component('ZeNhsNumberStep', ZeNhsNumberStep);
Vue.component('ZeNhs', ZeNhs);
Vue.component('ZeNotFoundStep', ZeNotFoundStep);
Vue.component('ZeSlider', ZeSlider);
Vue.component('ZeSliderDay', ZeSliderDay);
Vue.component('ZePasswordIndicator', ZePasswordIndicator);
Vue.component('ZeModal', ZeModal);
Vue.component('ZeImage', ZeImage);
Vue.component('ZeStarLetter', ZeStarLetter);
Vue.component('ZeDesktopCancellationReasons', ZeDesktopCancellationReasons);
Vue.component('ZeDesktopDateOfBirth', ZeDesktopDateOfBirth);
Vue.component('ZeDesktopFiveDigitCode', ZeDesktopFiveDigitCode);
Vue.component('ZeDesktopInput', ZeDesktopInput);
Vue.component('ZeDesktopNhs', ZeDesktopNhs);
Vue.component('ZeDesktopPassword', ZeDesktopPassword);
Vue.component('ZeDesktopProgress', ZeDesktopProgress);
Vue.component('ZeSupportRequestForm', ZeSupportRequestForm);
Vue.component('ZeEmail', ZeEmail);
Vue.component('ZePassword', ZePassword);
Vue.component('ZePatientLetters', ZePatientLetters);
Vue.component('ZeDesktopEmail', ZeDesktopEmail);
Vue.component('ZeFilteredLetters', ZeFilteredLetters);
Vue.component('ZeSaveToLocalStorage', ZeSaveToLocalStorage);
Vue.component('ZeTermsAndCondition', ZeTermsAndCondition);

document.addEventListener('DOMContentLoaded', () => {
  const $element = document.getElementById('app');

  $element &&
    new Vue({
      el: $element,

      components: {
        VersionPreview,
        ZeInfiniteScroll,
        ZeInput,
        ZeJournalCalendar,
        ZeJournalMedicineMonitoringPreSetup,
        ZeJournalMedicineMonitoringSetup,
        ZeJournalPainPadPreSetup,
        ZeJournalSettings,
        ZeButton,
        ZeProgress,
        ZeFiveDigitCode,
        ZeDateOfBirthStep,
        ZeDateOfBirth,
        ZeBackButton,
        ZeNhsNumberStep,
        ZeNhs,
        ZeNotFoundStep,
        ZeSlider,
        ZeSliderDay,
        ZePasswordIndicator,
        ZeModal,
        ZeDesktopCancellationReasons,
        ZeDesktopDateOfBirth,
        ZeDesktopFiveDigitCode,
        ZeDesktopInput,
        ZeDesktopNhs,
        ZeDesktopPassword,
        ZeDesktopProgress,
        ZeSupportRequestForm,
        ZeEmail,
        ZePassword,
        ZePatientLetters,
        ZeFilteredLetters,
        ZeSaveToLocalStorage,
        ZeReportTechnicalIssueScreen,
        ZeNhsBackIconText,
        ZeTermsAndCondition,
      },

      data: {
        showModal: false,
        visiblePopupId: '',
      },

      methods: {
        paperlessPreferencesChallenge() {
          app.$emit('show');
        },
      },
    });
});
