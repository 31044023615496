<template lang="html">
  <div class="ze-input">
    <div
      class="labels"
      :class="{ error: isError }"
    >
      <label
        v-if="labelLeft"
        class="left"
        :for="id"
      >
        {{ labelLeft }}
      </label>
      <label
        v-if="labelRight"
        class="right"
        for="text-input"
        @click="toggleHiddenChar"
      >
        {{ labelRight }}
      </label>
    </div>

    <input
      :id="id"
      ref="input"
      v-model="mutatedValue"
      v-validate="'required|email'"
      aria-label="Enter your email address"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :class="{
        correct: fields['patient[email]'] && fields['patient[email]'].dirty && fields['patient[email]'].valid,
        error: isError,
      }"
      :name="name"
      :placeholder="placeholder"
      type="email"
    />
    <div
      class="error-message"
      v-html="errorMessage"
    ></div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'placeholder',
    'labelLeft', // labels around the input
    'labelRight',
    'labelBottom', // is hidden if errorMessage is shown
    'type',
    'errorMessage',
    'isError',
    'value',
    'name',
    'autocomplete',
    'autofocus',
  ],

  data: function () {
    return {
      mutatedValue: this.value,
    };
  },

  mounted() {
    if (this.$props.autofocus) {
      this.$refs.input.focus();
    }
  },
};
</script>
