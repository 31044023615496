<template lang="html">
  <div>
    <div class='form'>
      <div class='desktop-check-list'>
        <div class='desktop-check-list-item'
              v-for="reason in reasons"
              @click="isButtonDisabled=false">
          <input :id="'reason-'+reason[1]" name="origin_cancellation_reason_id"
                  type="radio" :value=reason[1]>
          <label :for="'reason-'+reason[1]">{{reason[0]}}</label>
        </div>
      </div>
    </div>
    <div class='text-center'>
      <ze-button type='submit' text='Next' :disabled=isButtonDisabled></ze-button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'reasons'
  ],
  data () {
    return {
      isButtonDisabled: true
    }
  }
}
</script>
