<template lang="html">
  <div class="ze-not-found-step">
    <ze-nhs
      v-if="isNhsNumberVisible"
      autofocus="autofocus"
      :error-message="nhsErrorMessage"
      :is-error="isNhsError"
      :value="nhsNumber"
      @nhs-completed="updateNhsCompleted"
    />
    <ze-date-of-birth
      :date="dateOfBirth"
      :error-message="dateErrorMessage"
      :is-error="isDateError"
      @date-completed="updateDateCompleted"
    />
    <div class="ze-buttons bottom-fixed">
      <ze-button
        id="submit-btn-id"
        :disabled="!isSubmitButtonEnabled"
        klass="trackable"
        :text="submitButtonText"
        type="submit"
      />
      <a
        v-if="notFoundCount > 1"
        id="record-not-found-logout-btn"
        class="trackable"
        :href="logoutUrl"
      >
        <ze-button
          klass="white"
          text="Exit registration"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'dateErrorMessage',
    'dateOfBirth',
    'isDateError',
    'isNhsError',
    'logoutUrl',
    'nhsNumber',
    'nhsErrorMessage',
    'notFoundCount',
    'tokenRegistration',
    'buttonText',
  ],

  data() {
    return {
      isNhsCompleted: false,
      isDateCompleted: false,
    };
  },

  computed: {
    isSubmitButtonEnabled() {
      return (this.tokenRegistration || this.isNhsCompleted) && this.isDateCompleted;
    },
    isNhsNumberVisible() {
      return this.tokenRegistration != 'true';
    },
    submitButtonText() {
      return this.buttonText || 'Next';
    },
  },

  methods: {
    updateDateCompleted: function (isDateCompleted) {
      this.isDateCompleted = isDateCompleted;
    },
    updateNhsCompleted: function (isNhsCompleted) {
      this.isNhsCompleted = isNhsCompleted;
    },
  },
};
</script>
