<template lang="html">
  <div class="ze-input">
    <div
      class="labels"
      :class="{ error: isError }"
    >
      <label
        class="left"
        for="nhs-number"
      >
        NHS number
      </label>
    </div>
    <div
      class="nhs"
      :class="{ error: isError }"
    >
      <input
        id="nhs-number"
        ref="nhs_number"
        v-model="nhsNumber"
        aria-label="Enter your NHS number"
        inputmode="tel"
        name="nhs_number"
        pattern="[0-9\-]+"
        placeholder="Enter your NHS number"
        type="tel"
        @input="handleInput"
      />
    </div>
    <div
      class="error-message"
      v-html="errorMessage"
    ></div>
  </div>
</template>

<script>
import $_nhsNumber from '../mixins/$_nhs-number.js';

export default {
  mixins: [$_nhsNumber],

  props: {
    autofocus: Boolean,
    nhs: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      nhsNumber: this.nhs,
    };
  },

  mounted() {
    if (this.autofocus) {
      this.focusInput();
    }
  },

  methods: {
    handleInput(e) {
      const { nhsNumber } = this;
      this.limitLength(nhsNumber, e);
      this.nhsNumber = this.prettyPrintNHS(nhsNumber);
      this.dynamicDisable();
      this.$emit('input', this.nhsNumber);
    },
    focusInput() {
      this.$refs.nhs_number.focus();
    },
  },
};
</script>
