<template lang="html">
  <div>
    <div
      class="date"
      :class="{ error: isError }"
    >
      <input
        id="patient-date-of-birth"
        v-model="dob"
        aria-label="Enter your date of birth"
        autocomplete="off"
        :autofocus="autofocus"
        inputmode="numeric"
        maxlength="10"
        name="patient[date_of_birth]"
        pattern="[0-9\/]+"
        placeholder="DD/MM/YYYY"
        type="tel"
        @input="prettyPrintDob"
        @keypress="limitLength"
      />
    </div>
    <div class="ze-desktop-input">
      <p class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import $_date from '../mixins/$_date.js';

export default {
  mixins: [$_date],

  props: ['autofocus'],
};
</script>
